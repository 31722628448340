import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import css from "./assets/css/style.css";
import * as api from "./api/client";
import * as utils from "./utils";
import * as constants from "./constants";

Vue.config.productionTip = false;

Vue.prototype.$version = "2.0.6.0";

Vue.use({
  install(Vue) {
    Vue.prototype.$axios = axios.create({
      baseURL: `${process.env.VUE_APP_API_ORIGIN}/`,
    });
    Vue.prototype.$api = api;
    Vue.prototype.$utils = utils;
    Vue.prototype.$constants = constants;
  },
});

new Vue({
  router,
  store,
  vuetify,
  css,
  render: (h) => h(App),
}).$mount("#app");
