import Vue from "vue";
import Router from "vue-router";
import Store from "@/store/index.js";
import isMobile from "ismobilejs";

// リダイレクトエラー非表示
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

// レイアウト
const DefaultLayout = () => import("../layouts/DefaultLayout.vue");
const UserLayout = () => import("../layouts/UserLayout.vue");
const AdminLayout = () => import("../layouts/AdminLayout.vue");

// 共通画面
const NotFound = () => import("../views/common/NotFound.vue"); // 404エラー
const NotSupport = () => import("../views/common/NotSupport.vue"); // 動作対象外
const StartPage = () => import("../views/common/StartPage.vue"); // スタートページ
const UserLogin = () => import("../views/common/UserLogin.vue"); // ログイン

// 利用者画面
const OrderReservation = () => import("../views/user/OrderReservation.vue"); // 予約

// 管理者画面 - システム
const SystemNotice = () => import("../views/admin/system/SystemNotice.vue"); // 運営からのお知らせ
const EmailSend = () => import("../views/admin/system/EmailSend.vue"); // 一斉メール送信

// 管理者画面 - 予約
const ReserveCheck = () => import("../views/admin/reservation/ReserveCheck.vue"); // 予約状況確認
const ReserveList = () => import("../views/admin/reservation/ReserveList.vue"); // 予約状況一覧
const ReservationStatus = () => import("../views/admin/reservation/ReservationStatus.vue"); // 予約状況編集
const MultipleOrder = () => import("../views/admin/reservation/MultipleOrder.vue"); // 一括予約登録

// 管理者画面 - 注文
const OrderStatus = () => import("../views/admin/order/OrderStatus.vue"); // 注文状況一覧
const OrderHistory = () => import("../views/admin/order/OrderHistory.vue"); // 個人注文履歴

// 管理者画面 - 月次
const MonthlyReport = () => import("../views/admin/monthly/MonthlyReport.vue"); // 月別集計表出力
// const PreviewReports = () => import("../views/admin/monthly/PreviewReports.vue"); // 月別集計表出力(プレビュー)
const MonthlyClosing = () => import("../views/admin/monthly/MonthlyClosing.vue"); // 月次締め処理
const UserSeikyu = () => import("../views/admin/monthly/UserSeikyu.vue"); // 請求処理
const GyoshaSiharai = () => import("../views/admin/monthly/GyoshaSiharai.vue"); // 弁当業者支払

// 管理者画面 - マスタ
const CorpMaster = () => import("../views/admin/master/CorpMaster.vue"); // 企業マスタ
const OfficeMaster = () => import("../views/admin/master/OfficeMaster.vue"); // 事業所マスタ
const TraderMaster = () => import("../views/admin/master/TraderMaster.vue"); // 弁当業者マスタ
const BentoMaster = () => import("../views/admin/master/BentoMaster.vue"); // 弁当マスタ
const UserMaster = () => import("../views/admin/master/UserMaster.vue"); // 利用者マスタ
const DeptsMaster = () => import("../views/admin/master/DeptsMaster.vue"); // 部署マスタ
const EmailMaster = () => import("../views/admin/master/EmailMaster.vue"); // メール定型文マスタ
const SimebiUpdate = () => import("../views/admin/master/SimebiUpdate.vue"); // 締日登録
// const MenuCapture = () => import("../views/admin/master/MenuCapture.vue"); // 献立表取込
/*-- 2024.06.28 MCS)ADACHI ADD START --*/
const HolidayMaster = () => import("../views/admin/master/HolidayMaster.vue"); // 休日マスタ
/*-- 2024.06.28 MCS)ADACHI ADD END --*/
/* -- 2025.01.20 MCS)KONNO ADD START --*/
const JigyoKanriMaster = () => import("../views/admin/master/JigyoKanriMaster.vue"); // 事業所管理者マスタ
/* -- 2025.01.20 MCS)KONNO ADD END --*/
// 2025.02.06 MCS)SAITO ADD START
const KondateMaster = () => import("../views/admin/master/KondateMaster.vue"); // マニュアル一覧
// 2025.02.06 MCS)SAITO ADD END

// 管理者画面 - その他
const InternalNotice = () => import("../views/admin/other/InternalNotice.vue"); // 社内連絡
const EventCalendar = () => import("../views/admin/other/EventCalendar.vue"); // カレンダー
const SystemLog = () => import("../views/admin/other/SystemLog.vue"); // ログイン履歴
const ClientApp = () => import("../views/admin/other/ClientApp.vue"); // 専用ブラウザ
const PrintQR = () => import("../views/admin/other/PrintQR.vue"); // 受取確認用QRコード
const ManualList = () => import("../views/admin/other/ManualList.vue"); // マニュアル一覧
const ContractDetail = () => import("../views/admin/other/ContractDetail.vue"); // 契約情報

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // --------------------------------
    // デフォルトレイアウト
    // --------------------------------
    {
      path: "/",
      component: DefaultLayout,
      children: [
        // 認証画面
        {
          path: "/",
          component: StartPage,
          name: "StartPage",
          meta: {
            requiresAuth: false,
          },
        },
        // ログイン画面
        {
          path: "/login/:kigyoCd",
          component: UserLogin,
          name: "UserLogin",
          meta: {
            requiresAuth: false,
          },
        },
        // モバイルページ
        {
          path: "/notsupport",
          component: NotSupport,
          name: "NotSupport",
          meta: {
            requiresAuth: false,
          },
        },
      ],
    },

    // --------------------------------
    // 注文画面用レイアウト
    // --------------------------------
    {
      path: "/user",
      component: UserLayout,
      children: [
        // 注文予約画面
        {
          path: "reservation",
          component: OrderReservation,
          name: "OrderReservation",
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },

    // --------------------------------
    // 管理画面用レイアウト
    // --------------------------------
    {
      path: "/admin",
      component: AdminLayout,
      children: [
        // --------------------------------
        // サブメニュー：システム
        // --------------------------------
        {
          path: "system/notice",
          component: SystemNotice,
          name: "SystemNotice",
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.04.22 MCS)SAITO ADD START
        {
          path: "system/sendemail",
          component: EmailSend,
          name: "EmailSend",
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.04.22 MCS)SAITO ADD END
        // --------------------------------
        // サブメニュー：予約
        // --------------------------------
        // 予約状況確認
        {
          path: "reservation/check",
          component: ReserveCheck,
          name: "ReserveCheck",
          meta: {
            requiresAuth: true,
          },
        },
        // 予約状況一覧
        {
          path: "reservation/list",
          component: ReserveList,
          name: "ReserveList",
          meta: {
            requiresAuth: true,
          },
          // 2024.05.07 MCS)KONNO ADD START
          props: true,
          // 2024.05.07 MCS)KONNO ADD END
        },
        // 予約状況編集
        {
          path: "reservation/status",
          component: ReservationStatus,
          name: "ReservationStatus",
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        // 2024.05.02 MCS)KONNO ADD START
        // 一括予約登録
        {
          path: "reservation/multiple",
          component: MultipleOrder,
          name: "MultipleOrder",
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.05.02 MCS)KONNO ADD END
        // --------------------------------
        // サブメニュー：注文
        // --------------------------------
        // 注文状況一覧
        {
          path: "order/list",
          component: OrderStatus,
          name: "OrderStatus",
          meta: {
            requiresAuth: true,
          },
        },
        // 個人注文履歴
        {
          path: "order/history",
          component: OrderHistory,
          name: "OrderHistory",
          meta: {
            requiresAuth: true,
          },
        },
        // --------------------------------
        // サブメニュー：月次
        // --------------------------------
        // 献立表取込 2022.07.05 コメントアウト
        // {
        //   path: "monthly/menu",
        //   component: MenuCapture,
        //   name: "MenuCapture",
        //   meta: {
        //     requiresAuth: true,
        //   },
        // },
        // 月別集計表出力
        {
          path: "monthly/report",
          component: MonthlyReport,
          name: "MonthlyReport",
          meta: {
            requiresAuth: true,
          },
        },
        // 月別集計表出力(プレビュー)
        // {
        //   path: "monthly/preview",
        //   component: PreviewReports,
        //   name: "PreviewReports",
        //   props: true,
        //   meta: {
        //     requiresAuth: true,
        //   },
        // },
        // 2024.01.24 MCS)SAITO ADD START
        {
          path: "monthly/closing",
          component: MonthlyClosing,
          name: "MonthlyClosing",
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.01.24 MCS)SAITO ADD END
        // 請求処理
        {
          path: "monthly/seikyu",
          component: UserSeikyu,
          name: "UserSeikyu",
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.02.09 MCS)SAITO ADD START
        {
          path: "monthly/siharai",
          component: GyoshaSiharai,
          name: "GyoshaSiharai",
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.02.09 MCS)SAITO ADD END
        // --------------------------------
        // サブメニュー：マスタ
        // --------------------------------
        // 企業マスタ
        {
          path: "master/corp",
          component: CorpMaster,
          name: "CorpMaster",
          meta: {
            requiresAuth: true,
          },
        },
        // 事業所マスタ
        {
          path: "master/office",
          component: OfficeMaster,
          name: "OfficeMaster",
          meta: {
            requiresAuth: true,
          },
        },
        // 弁当業者マスタ
        {
          path: "master/trader",
          component: TraderMaster,
          name: "TraderMaster",
          meta: {
            requiresAuth: true,
          },
        },
        // 弁当マスタ
        {
          path: "master/bento",
          component: BentoMaster,
          name: "BentoMaster",
          meta: {
            requiresAuth: true,
          },
        },
        // 利用者マスタ
        {
          path: "master/user",
          component: UserMaster,
          name: "UserMaster",
          meta: {
            requiresAuth: true,
          },
        },
        // 部署マスタ
        {
          path: "master/depts",
          component: DeptsMaster,
          name: "DeptsMaster",
          meta: {
            requiresAuth: true,
          },
        },
        /* -- 2025.01.20 MCS)KONNO ADD START --*/
        // 事業所管理者マスタ
        {
          path: "master/jigyokanri",
          component: JigyoKanriMaster,
          name: "JigyoKanriMaster",
          meta: {
            requiresAuth: true,
          },
        },
        /* -- 2025.01.20 MCS)KONNO ADD END --*/
        // メール定型文マスタ
        {
          path: "master/email",
          component: EmailMaster,
          name: "EmailMaster",
          meta: {
            requiresAuth: true,
          },
        },
        /*-- 2024.06.28 MCS)ADACHI ADD START --*/
        // 休日マスタ
        {
          path: "master/holiday",
          component: HolidayMaster,
          name: "HolidayMaster",
          meta: {
            requiresAuth: true,
          },
        },
        /*-- 2024.06.28 MCS)ADACHI ADD END --*/
        // 2024.02.15 MCS)SAITO ADD START
        {
          path: "master/simebi",
          component: SimebiUpdate,
          name: "SimebiUpdate",
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.02.15 MCS)SAITO ADD END
        // 2025.02.06 MCS)SAITO ADD START
        {
          path: "master/kondate",
          component: KondateMaster,
          name: "KondateMaster",
          meta: {
            requiresAuth: true,
          },
        },
        // 2025.02.06 MCS)SAITO ADD END
        // --------------------------------
        // サブメニュー：その他
        // --------------------------------
        // お知らせ管理
        {
          path: "other/internal_notice",
          component: InternalNotice,
          name: "InternalNotice",
          meta: {
            requiresAuth: true,
          },
        },
        // 年間予定管理
        {
          path: "other/calendar",
          component: EventCalendar,
          name: "EventCalendar",
          meta: {
            requiresAuth: true,
          },
        },
        // ログイン履歴
        {
          path: "other/log",
          component: SystemLog,
          name: "SystemLog",
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.03.28 MCS)KONNO ADD START
        {
          path: "other/client",
          component: ClientApp,
          name: "ClientApp",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "other/qr",
          component: PrintQR,
          name: "PrintQR",
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.03.28 MCS)KONNO ADD END
        // 2024.04.09 MCS)KONNO ADD START
        {
          path: "other/manual",
          component: ManualList,
          name: "ManualList",
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.04.09 MCS)KONNO ADD END
        // 2024.05.15 MCS)KONNO ADD START
        {
          path: "other/contract",
          component: ContractDetail,
          name: "ContractDetail",
          meta: {
            requiresAuth: true,
          },
        },
        // 2024.05.15 MCS)KONNO ADD END
      ],
    },

    // --------------------------------
    // 存在しないページ
    // --------------------------------
    {
      path: "/:notFound(.*)",
      component: NotFound,
      name: "NotFound",
    },
  ],
});

// ------------------------------
// ナビゲーションガード
// ------------------------------
router.beforeEach((to, from, next) => {
  // --------------------------------
  // モバイル判定
  // --------------------------------
  // モバイルデバイスかどうかを判定
  const mobile = isMobile(window.navigator).any;
  // モバイルデバイスの場合、リダイレクト
  if (mobile && to.name !== "NotSupport") {
    next({ name: "NotSupport" });
  } else if (!mobile && to.name == "NotSupport") {
    next(false);
    return;
  }
  // --------------------------------
  // ログインチェック
  // --------------------------------
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!Store.state.isLogin) {
      if (Store.state.kigyoCode != "") {
        next({
          name: "UserLogin",
          query: { kigyoCd: Store.state.kigyoCode },
        });
        alert("ログイン期限が切れました。\n再度ログインしてください。");
        return;
      } else {
        next({ name: "StartPage" });
        alert("ログイン期限が切れました。\n再度ログインしてください。");
        return;
      }
    }
  }
  // --------------------------------
  // 権限チェック（URLで判定）
  // --------------------------------
  if (String(to.fullPath).match(/user(?=\/)/)) {
    // 管理者が利用者画面を開こうとした場合、リダイレクト
    if (Store.state.adminAuth != "0" || Store.state.jigyoAuth != "0") {
      next(false);
      alert("表示権限がありません。\nアカウントを確認してください。");
      return;
    }
  } else if (String(to.fullPath).match(/admin(?=\/)/)) {
    // 利用者が管理者画面を開こうとした場合、リダイレクト
    if (Store.state.adminAuth == "0" && Store.state.jigyoAuth == "0") {
      next(false);
      alert("表示権限がありません。\nアカウントを確認してください。");
      return;
    }
    // 管理者以外が月次管理画面を開こうとした場合、リダイレクト
    if (String(to.fullPath).match(/monthly(?=\/)/) && Store.state.adminAuth == "0") {
      // 請求情報、支払情報は除外
      if (!String(to.fullPath).match(/seikyu|siharai/)) {
        next(false);
        alert("表示権限がありません。\nアカウントを確認してください。");
        return;
      }
    }
    // 管理者以外がマスタ管理画面を開こうとした場合、リダイレクト
    if (String(to.fullPath).match(/master(?=\/)/) && Store.state.adminAuth == "0") {
      // 弁当業者マスタ、メニューマスタ、利用者マスタは除外
      if (!String(to.fullPath).match(/trader|bento|user/)) {
        next(false);
        alert("表示権限がありません。\nアカウントを確認してください。");
        return;
      }
    }
    // 管理者以外がその他管理画面を開こうとした場合、リダイレクト
    if (String(to.fullPath).match(/other(?=\/)/) && Store.state.adminAuth == "0") {
      // クライアント、QRコード、マニュアル一覧は除外
      if (!String(to.fullPath).match(/client|qr|manual/)) {
        next(false);
        alert("表示権限がありません。\nアカウントを確認してください。");
        return;
      }
    }
  }
  next();
});

export default router;
